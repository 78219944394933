@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

/* Root Variables */
:root {
  --main-bg-color: #ffffff;
  --main-text-color: #333333;
  --primary-color: #000000;
  --primary-hover-color: #31b6f3;
  --secondary-color: #f0f0f0;
  --secondary-hover-color: #e0e0e0;
  --accent-color: #ff8e6b;
  --accent-hover-color: #FF7043;
  --disabled-color: #cccccc;
  --disabled-text-color: #666666;
  --link-color: #007bff;
  --border-color: #dddddd;
  --light-bg-color: #f9f9f9;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --overlay-color: rgba(0, 0, 0, 0.8);
  --status-ongoing-color: #ef3bc2;
  --status-endsoon-color: #863bef;
  --status-ended-color: #afafaf;
  --status-coming-color: #ff9933;
  --placeholder-bg-color: #000000;
  --info-bar-bg-color: #78d4ff;
  /* --twitter-blue: #1da1f2; */
  --twitter-blue: #31b6f3;
  --error-color: #ff4d4d;
}


/* App.css */


/* ... 既存のスタイル */


/* Global Styles */
body {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 300;
  letter-spacing: 0.02em;
  margin: 0;
  padding: 0;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

.no-scroll {
  overflow: hidden;
}

.App {
  padding: 10px;
}



.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000000;
  min-width: 250px;
  max-width: 95%;
  animation: toast-slide-down 0.3s ease-out;
}

.toast p {
  margin: 0;
  font-size: 14px;
}

.toast button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  padding: 0 0 0 12px;
  margin-left: 12px;
}

@keyframes toast-slide-down {
  from {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

/* Header Styles */
.main-header {
  position: relative;
  height: auto;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
  padding-top: 100px;
}

.main-header h1 {
  text-align: center;
  font-size: 1.6em;
  letter-spacing: 0.15em;
  margin: 0;
  margin-bottom: 10px;
}

.board-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.board-description {
  font-size: 0.6em;
  color: var(--main-text-color);
  text-align: center;
  padding: 0px;
  max-width: 600px;
  margin: 20px auto;
}

.sub-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 30px;
}

.sub-title-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 10%;
  /* margin-right: 10px; */
}

.sub-title {
  font-size: 0.8em;
  letter-spacing: 0.15em;
}

.sub-title-member-count {
  font-size: 0.8em;
  letter-spacing: 0.15em;
  padding-left: 5px;
}
/* Past Events Link Styles */
.past-events-link {
  position: absolute;
  bottom: -10px; 
  right: 10px; 
  font-size: 0.6em;
  color:#595959;
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.past-events-link svg {
  color:#898989;
  margin-right: 4px; /* アイコンとテキストの間のスペースを調整します */
}

/* Card Styles */
.my-masonry-grid {
  display: flex;
  margin-left: -10px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px;
  background-clip: padding-box;
}

.card {
  background: var(--main-bg-color);
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px var(--shadow-color);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.card img {
  width: 100%;
  display: block;
  border-bottom: 1px solid var(--border-color);
  transition: transform 0.2s ease;
}

.image-container {
  position: relative;
}

.image-container .new-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  border-bottom: 0px;
  /* height: 40px; */
}



.placeholder {
  width: 100%;
  padding-bottom: 56.25%;
  background: var(--placeholder-bg-color);
  transition: transform 0.3s ease;
}

.card-title {
  font-size: 0.6em;  
  padding: 8px;  
  margin: 0px;
  margin-bottom: -10px;
  font-weight: 400;
}

.card-tags {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;
  gap: 8px;
}

.tag-link {
  font-size: 0.8em;
  font-weight: 300;
  padding: 4px 8px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.tag-list .normal-tag, .normal-tag, .tag-list .content-tag, .content-tag, .tag-list .sub_category-tag, .sub_category-tag, .tag-list .ip-tag, .ip-tag {

  background-color: #fff;
  color: #949494;  
  border: 1.0px solid #c0c0c0;  
  border-radius: 15px;
}

.tag-list .location-tag, .location-tag {
  background-color: #fff;
  color: #53b1ad;  
  border: 1.0px solid #97d6d3;  
  border-radius: 15px;  
}


.area-style-carousel {
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom: 1px solid var(--border-color);
}

.area-style-tags-container {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 10px 0;
}

.area-style-tags-container::-webkit-scrollbar {
  display: none;
}

.area-style-tags-container .tag-item {
  flex: 0 0 auto;
  width: 100px;
  text-align: center;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 16px;
  font-size: 0.8em;
}

.area-style-tags-container .area-tag {
  background-color: #fff;
  color: #53b1ad;
  border: 1.0px solid #97d6d3;
  border-radius: 5px;
}

.area-style-tags-container .style-tag {
  background-color: #fff;
  color: #7e57c2;
  border: 1.0px solid #b39ddb;
  border-radius: 5px;
}


.card-content {
  padding: 8px;
}

.card-content-nothing {
  font-size: 0.8em;
  color: var(--disabled-text-color);
}

.session {
  margin-bottom: 6px;
}

.date, .time, .description {
  margin: 0;
  padding: 2px 0;
}

.date {
  font-size: 1.4em;
  font-weight: 400;
}

.time {
  font-size: 0.6em;
}

.description {
  font-size: 0.6em;
  color: var(--main-text-color);
}

/* Fullscreen Card Styles */
.fullscreen-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  cursor: pointer;
}

.fullscreen-card .card-content-scroll .card-title {
  font-size: 1.0em;
  font-weight: bold;
  text-align: left;
  margin: 16px 0;
  padding: 0 16px;
}

.fullscreen-card .card-overview {
  font-size: 0.8em;  
  margin: 0px 0 8px 0;
  padding: 0 16px;
  font-weight: 200;
}

.fullscreen-card .card-source-info {
  font-size: 0.6em;  
  margin: 10px 0 10px 0;
  padding: 0 16px;
  font-weight: 200;
}

.card-source-info a {
  color: #898989; /* 好きな色を指定 */
  text-decoration: none; /* 下線を消したい場合 */
  font-style: italic;
}

.fullscreen-card-content {
  width: 90%;
  height: 90%;
  max-width: 800px;
  background: var(--main-bg-color);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.fullscreen-card-content-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.card-content-scroll {
  overflow-y: auto;
  flex-grow: 1;
}

.fullscreen-card-content img {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 1px solid var(--border-color);
}

.fullscreen-card-content .placeholder {
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  background: var(--placeholder-bg-color);
}

.fullscreen-card-content .card-content {
  padding: 16px;
}

.fullscreen-card-content .title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin: 16px 0;
}


/* FullScreenCardアニメーションスタイル */
.fullscreen-card {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.fullscreen-card.visible {
  opacity: 1;
  visibility: visible;
}

.fullscreen-card-content {
  transform: scale(0.9);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.fullscreen-card.visible .fullscreen-card-content {
  transform: scale(1);
  opacity: 1;
}

/* Tab Styles */
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 0.8em;
}

.tabs button {
  flex-grow: 1;
  width: 25%;
  background: none;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 1em;
  transition: none;
  text-align: center;
  color: inherit;
}

.tabs button:focus {
  outline: none;
  color: inherit;
}

.tabs button:active {
  color: inherit;
}

.tabs .active {
  font-weight: bold;
  border-bottom: 2px solid var(--main-text-color);
  color: inherit;
}

.tab-content {
  padding: 16px;
  min-height: 350px;
}

/* Section Styles */
.section-title {
  font-size: 0.8em;
  margin-bottom: 8px;
  font-weight: bold;
  color: var(--main-text-color);
}

.term-info {
  margin-bottom: 16px;
  padding: 6px;
  border-radius: 8px;
  background-color: var(--light-bg-color);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.term-info p {
  margin: 4px 0;
  font-size: 0.9em;
}

/* Status Styles */
.ongoing, .ongoing-small {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 8px;
  background-color: var(--status-ongoing-color);
  color: var(--main-bg-color);
  border-radius: 4px;
  font-size: 0.8em;
  float: right;
}

.ongoing-small {
  margin-left: 4px;
  padding: 1px 4px;
  font-size: 0.6em;
}

.endsoon, .endsoon-small {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 8px;
  background-color: var(--status-endsoon-color);
  color: var(--main-bg-color);
  border-radius: 4px;
  font-size: 0.8em;
  float: right;
}

.endsoon-small {
  margin-left: 4px;
  padding: 1px 4px;
  font-size: 0.6em;
}

.ended, .ended-small {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 8px;
  background-color: var(--status-ended-color);
  color: var(--main-bg-color);
  border-radius: 4px;
  font-size: 0.8em;
  float: right;
}

.ended-small {
  margin-left: 4px;
  padding: 1px 4px;
  font-size: 0.6em;
}

.coming, .coming-small {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 8px;
  background-color: var(--status-coming-color);
  color: var(--main-bg-color);
  border-radius: 4px;
  font-size: 0.8em;
  float: right;
}

.coming-small {
  margin-left: 4px;
  padding: 1px 4px;
  font-size: 0.6em;
}

/* Term Date Styles */
.term-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Image Gallery Styles */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.image-gallery img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  background: var(--main-bg-color);
}

/* Comments Styles */
.comments-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
}

.comment-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.comment-avatar {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  margin-right: 10px;
}

.comment-content {
  flex-grow: 1;
  word-wrap: break-word;
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.comment-user {
  font-weight: bold;
  margin-right: 8px;
}

.comment-time {
  font-size: 0.8em;
  color: var(--disabled-text-color);
}

.comment-text {
  margin: 4px 0;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.comment-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.comment-actions button {
  background: none;
  border: none;
  color: var(--link-color);
  cursor: pointer;
  margin-right: 10px;
  font-size: 0.8em;
}

.comment-form.active {
  display: flex;
  padding: 16px;
}

.comment-form input {
  flex-grow: 1;
  padding: 10px;
  padding-left: 14px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  margin-right: 10px;
}

.comment-form button {
  padding: 10px 20px;
  border: none;
  background: var(--primary-color);
  color: var(--main-bg-color);
  border-radius: 20px;
  cursor: pointer;
}

/* Button Styles */
.main-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  padding: 8px;
}

.button-slot {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-slot.left {
  flex: 1;
}

.button-slot.center {
  flex: 8;
}

.button-slot.right {
  flex: 1;
}

.remove-from-board-button,
.add-to-board-button,
.close-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-from-board-button {
  background-color: var(--main-bg-color);
  color: var(--primary-color);
}

.add-to-board-button {
  background-color: var(--main-bg-color);
  color: var(--primary-color);
}

.close-button {
  background-color: #fff;
  color: #333;
}

.fullscreen-bottom-button-icon {
  margin-right: 5px;
}

.already-added-text {
  font-size: 0.6em;
  color:var(--disabled-text-color);
}

/* Login Prompt Styles */
.login-prompt {
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
}

/* Loading and Message Styles */
.loading-content, .loading-more, .no-cards-message, .no-content {
  text-align: center;
  color: var(--disabled-text-color);
  padding: 10px;
}

.no-image {
  width: 100%;
}

.overview-text {
  font-size: 1.0em;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;  
}

.overview-update-text{
  text-align: right;
  font-size: 0.6em;
  padding-top: 20px;
  padding-right: 8px;
  color: #333;
}

.term-update-text{
  text-align: right;
  font-size: 0.6em;
  padding-top: 10px;
  padding-right: 8px;
  color: #333;
}

.no-overview {
  padding-top: 23px;
}

.tab-content {
  padding: 16px;
}

.term-date-time {
  font-size: 0.9em;
}

.term-date-time span{
  font-size: 0.6em;
}

.term-info {
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--light-bg-color);
  box-shadow: 0 2px 4px var(--shadow-color);
  display: flex;
  flex-direction: column;
}

.term-info p {
  margin: 4px 0;
  font-size: 0.9em;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.calendar-icon {
  display: inline-block;
  line-height: 0;
}

.calendar-icon img {
  width: 24px;
  height: 24px;
  transition: opacity 0.3s ease;
}

.disabled-icon img {
  opacity: 0.5;
}



.topic-card {
  background: var(--main-bg-color);
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px var(--shadow-color);
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative;
}

.topic-card-content {
  display: flex;
  align-items: flex-start;
  padding: 10px 10px 5px 10px;
}

.topic-card-thumbnail {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  flex-shrink: 0;
}

.topic-card-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.topic-card-thumbnail svg {
  color: var(--disabled-text-color);
}

.topic-card-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.topic-card-title {
  font-size: 0.6em;
  margin: 0;
  padding: 0;
}

.topic-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 10px 10px 10px;
}

.topic-card-attention {
  display: flex;
  align-items: center;
}

.topic-card-attention svg {
  margin-right: 2px;
}

.topic-card-date {
  font-size: 0.5em;
  color: #888;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .topic-card:active {
    transform: scale(1.02);
  }
}

@media (min-width: 769px) {
  .topic-card:hover {
    transform: scale(1.02);
  }
}





/* NewsCard Styles */
.news-card {
  /* background: var(--main-bg-color); */
  background: #ffffffcc;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px var(--shadow-color);
  cursor: pointer;
  padding: 0px 10px;
}

.news-card-title {
  font-size: 1.0em;
  font-weight: 200;
  margin: 0px;
  margin-top: 10px;
  padding: 0;
}

.news-list {
  list-style-type: none;
  padding: 0;
}

.news-item {
  margin-bottom: 8px;
  font-size: 0.6em;
  position: relative; /* 絶対位置のための基準を設定 */
  padding-left: 10px; /* 罫線とテキストの間のスペース */
}

.news-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.5px; /* 罫線の幅 */
  height: 100%; /* 罫線の高さ */
  background-color: rgb(185, 185, 185); /* 罫線の色 */
}

/* ハイライト効果のアニメーション */
@keyframes highlightFade {
  0% { background-color: yellow; }
  100% { background-color: transparent; }
}

.news-item.highlight {
  animation: highlightFade 1s ease-out;
}



/* FullScreenNewsCard Styles */
.fullscreen-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
}

.fullscreen-card-body {
  padding: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}

.fullscreen-card-body .news-item {
  margin-bottom: 24px;
}

.fullscreen-card-body .news-item .news-item-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fullscreen-card-body .news-item .news-item-thumbnail {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-card-body .news-item .news-item-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.fullscreen-card-body .news-item .news-item-thumbnail svg {
  color: var(--disabled-text-color);
}

.fullscreen-card-body .news-item h3 {
  font-size: 1.2em;
  margin: 0;
}

.fullscreen-card-body .news-item p {
  font-size: 0.9em;
}

.news-item-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.news-item-attention {
  display: flex;
  align-items: center;
}

.news-item-attention svg {
  margin-right: 2px;
}

.article-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.article-search a {
  color: #000000;
  text-decoration: none;
}

.news-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.news-item-tags-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.news-item-date-container {
  flex-shrink: 0;
  margin-left: 10px;
}

.news-item-tags-container .tag-link {
  font-size: 0.7em;
  padding: 2px 6px;
  cursor: pointer;
  margin-right: 5px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.news-item-tags-container .normal-tag {
  background-color: #ffffff00;
  color: #949494;
  border: 1.0px solid #c0c0c0;
  border-radius: 15px;
}

.news-item-tags-container .location-tag {
  background-color: #ffffff00;
  color: #53b1ad;
  border: 1.0px solid #97d6d3;
  border-radius: 15px;
}

.release-date {
  font-size: 0.7em;
  color: #888;
  margin: 0;
  text-align: right;
}

.news-item-header h3.news-title {
  font-size: 1.4em;  
}

.news-item p.article {
  font-size: 1.4em;
  margin-top: 10px;
}

.close-news-button {
  padding: 0px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--disabled-text-color);
}



/* Fullscreen Add Form Styles */
.fullscreen-add-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  cursor: pointer;
}

.fullscreen-add-form .add-url-form button {
  padding: 10px 20px;
  border: none;
  background: var(--primary-color);
  color: var(--main-bg-color);
  border-radius: 20px;
  cursor: pointer;
}

.floating-add-button {
  position: fixed;
  bottom: 20px;
  right: 90px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  box-shadow: 0 2px 10px var(--shadow-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
}

.add-form-content {
  background: var(--main-bg-color);
  padding: 20px;
  border-radius: 8px;
  width: calc(100% - 40px);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.add-form-content form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-form-content input[type="url"] {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.add-form-content button {
  padding: 10px;
  background: var(--primary-color);
  color: var(--main-bg-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.add-form-title {
  margin-top: 20px;
  font-weight: 600;
}

.add-form-description {
  margin-bottom: 10px;
  font-weight: 200;
  font-size: 0.6em;
}

/* 検索フロートボタンのスタイル */
.floating-search-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px var(--shadow-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
}

/* サーチモーダルのスタイル */
.modal-overlay .tag-modal-content {
  display: flex;
  flex-direction: column;
  height: 70vh;
  max-width: 90%;
  width: 900px; 
  padding: 20px;
  box-sizing: border-box;
}

.modal-overlay .tag-modal-content h4 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1em;
  color: var(--main-text-color);
}

.tag-list-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.tag-list.my-masonry-grid {
  display: flex;
  margin-left: -10px;
  width: auto;
}

.tag-list .my-masonry-grid_column {
  padding-left: 10px;
  background-clip: padding-box;
}

.tag-item {
  width: 100%;
  color: var(--main-text-color);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-block;
  margin-bottom: 10px;
  border: 1px solid var(--border-color);
}

span.tag-item:active {
  animation: tagPulse 0.3s ease-in-out;
  background-color: #fff;
}

/* .tag-list .normal-tag, .normal-tag {
  background-color: #fff;
  color: #949494;  
  border: 1.0px solid #c0c0c0;  
  border-radius: 15px;
}

.tag-list .location-tag, .location-tag {
  background-color: #fff;
  color: #53b1ad;  
  border: 1.0px solid #97d6d3;  
  border-radius: 15px;  
} */

/* .tag-list .area-tag, .area-tag {
  background-color: #fff;
  color: #ff7043;  
  border: 1.0px solid #ffab91;  
  border-radius: 15px;  
}

.tag-list .style-tag, .style-tag {
  background-color: #fff;
  color: #7e57c2;  
  border: 1.0px solid #b39ddb;  
  border-radius: 15px;  
} */

.tag-item.selected {
  background-color: var(--primary-color);
  color: var(--main-bg-color);
  border-color: var(--primary-color);
}

.tag-item.excluded {
  background-color: var(--error-color);
  color: var(--main-bg-color);
  border-color: var(--error-color);
  text-decoration: line-through;
}

/* .tag-item.disabled {
  opacity: 0.2;
  cursor: not-allowed;
} */

/* Tag Animation Styles */
.tag-item-wrapper {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  transform: scale(1);
}

.tag-item-wrapper.fade-out {
  opacity: 0;
  transform: scale(0.8);
}

.tag-item-wrapper.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* タグアニメーションのスタイル */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.95);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes tagPulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.tag-item.animating {
  animation: pulse 0.4s ease-in-out infinite;
}

.tag-modal-content .modal-header {
  display: flex;
  align-items: center;
  padding: 0 0 10px 0;
}

.toggle-selection-mode {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  font-weight: 800;
  color: var(--main-text-color);
}

.toggle-selection-mode svg {
  padding-top:2px;
  margin-left: 6px;
}

.filter-type-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.8em;
  color: var(--main-text-color);
}

.filter-type-toggle svg {
  margin-right: 4px;
}

/* これがないと最後まで読み込まれないので一旦ホワイト */
.tags-count {
  color: #fff;
  font-size: 0.6em;
}


.search-actions {
  display: flex;
  justify-content: space-between;
  /* padding: 0px; */
  background-color: var(--main-bg-color);
  /* border-top: 1px solid var(--border-color); */
  position: sticky;
  bottom: 0;
}

.search-actions .clear-all-button,
.search-actions .search-button {
  padding: 10px 20px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.2s ease;
}

.clear-all-button {
  background-color: var(--secondary-color);
  color: var(--main-text-color);
}

.clear-all-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.search-button {
  background-color: var(--primary-color);
  color: var(--main-bg-color);
}

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 10px;
  padding-right: 40px; /* リセットボタンのためのスペース */
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 0.8em;
}

.search-input-container .reset-search-button {
  position: absolute;
  right: 8px;
  top: -10px;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: var(--disabled-text-color);
}


/* Animation Styles */
@keyframes highlight {
  0% {
    transform: scale(1);
    background-color: #ffffcc;
  }
  50% {
    transform: scale(1.05);
    background-color: #ffff99;
  }
  100% {
    transform: scale(1);
    background-color: #ffffcc;
  }
}

.new-card {
  animation: highlight 1s ease-in-out infinite;
}

.ended-term p {
  text-decoration: line-through;
  color: var(--disabled-text-color);
}

.disabled-link {
  background-color: var(--disabled-color) !important;
  color: var(--disabled-text-color) !important;
}

/* Overlay Styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.overlay img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

/* Info Bar Styles */
/* .info-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 0.8em;
  z-index: 1000000;
  display: none;
  box-sizing: border-box;
}

.info-bar.show {
  display: block;
} */

.app-top {
  padding: 1rem;
}

/* Term Options Styles */
.term-options {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
}

.options-button {
  background: none;
  border: none;
  /* font-size: 24px; */
  cursor: pointer;
  color: var(--main-text-color);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  background-color: var(--main-bg-color);
  padding: 24px;
  border-radius: 12px;
  max-width: 400px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: var(--main-text-color);
}

.modal-description {
  font-size: 0.7em;
}

.modal-content ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0px;
  margin-top: 0px;
}

.modal-content li {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

.modal-content li:last-child {
  border-bottom: none;
}

.modal-content li.selected {
  background-color: #e6f2ff;
  font-weight: bold;
}

.modal-content button {
  margin-top: 20px;
  width: 100%;
  padding: 12px 20px;
  background-color: var(--primary-color);
  color: var(--main-bg-color);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.2s ease;
}

.modal-content button:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
}

.modal-content button.update-disabled {
  background-color: var(--disabled-color);
  color: var(--disabled-text-color);
  cursor: not-allowed;
}

.modal-content button .remain-time {
  display: block;
  font-size: 0.8em;
  margin-top: 5px;
}


.modal-content .new-board-button {
  display: inline-block;
  margin-top: 16px;
  font-size: 0.6em;
  color: var(--main-text-color);
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 0;
  width: auto;
  text-align: left;
  text-decoration: underline;
}

.modal-content .role-section-name{
  font-size: 0.6em;  
  padding: 0;
  padding-top: 5px;
  margin: 0;
}

.change-button button:disabled {
  background-color: var(--main-bg-color);
  text-decoration: none;
}

.loading-tables{
  font-size: 0.8em;
  color: var(--disabled-text-color);
  padding-top: 5px;
  padding-bottom: 5px;    
}

.modal-form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1em;
  box-sizing: border-box;
}

.modal-content textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1em;
  box-sizing: border-box;
  margin-bottom: 0px;
  resize: vertical;
}

.char-count {
  text-align: right;
  font-size: 0.8em;
  color: var(--disabled-text-color);
  margin-top: 4px;
  margin-bottom: 10px;
}

.error-message {
  color: var(--error-color);
  font-size: 0.9em;
  margin-bottom: 10px;
}

/* Drawer Styles */
.drawer-toggle {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1900;
  color: var(--main-text-color);
  display: flex;
  align-items: center;
}

.drawer-toggle span {
  font-size: 0.6em;
  font-weight: 500;
  margin-left: 8px; /* アイコンとテキストの間のスペースを調整 */
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay-color);
  z-index: 1000;
}

.drawer {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: var(--main-bg-color);
  box-shadow: 0 0 10px var(--shadow-color);
  transition: left 0.3s ease-in-out;
  z-index: 1100;
  overflow-y: auto;
}

.drawer.open {
  left: 0;
}

.drawer-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.close-drawer {
  position: fixed;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--twitter-blue);
  z-index: 1100;
}

.drawer-nav {
  flex-grow: 1;
  margin-top: 40px;
}

.drawer-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-item, .board-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1.0em;
}

.board-item{
  padding: 10px 0;
  font-size: 1.0em;
}

.nav-item svg, .board-item svg {
  margin-right: 15px;
  color: var(--twitter-blue);
}

.drawer-nav {
  margin-top: 40px;
}

.loading-boards{
  font-size: 0.8em;
  padding-top: 10px;
}

.nav-section {
  color: var(--primary-color);
  font-size: 0.8em;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 5px;
}

.board-item {
  display: flex;
  align-items: center;
}

.board-item-content {
  display: flex;
  flex-direction: column;
  margin-left: 0px;
}

.board-item-content .user-name {
  font-size: 0.6em;
  color: var(--disabled-text-color);
  margin-top: 2px;
}

.no-boards{
  font-size: 0.8em;
  color: var(--disabled-text-color);
  padding-top: 5px;
  padding-bottom: 5px;  
}

/* フォローリストのボード項目のスタイル */
.follow-items.board-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.follow-items.board-item svg {
  margin-right: 15px;
  flex-shrink: 0;
}

.follow-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.follow-item-content span {
  font-size: 1em;
}

.follow-item-content .user-name {
  font-size: 0.6em;
  color: var(--disabled-text-color);
  margin-top: 2px;
}

.followed-sample{
  font-size: 0.8em;
}

.logout-button, .login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-button {
  background-color: var(--primary-color);
  color: var(--main-bg-color);
  border: none;
}

.logout-button {
  background-color: #fff;
  color: #000000;
  border: 1px solid black; /* 枠を黒に設定 */
}

.logout-button span, .login-button span {
  padding-left: 20px;
  padding-right: 20px;
}

.logout-button svg, .login-button svg {
  width: 14px;
}

.login-prompt p {
  font-size: 0.8em;
}

.drawer-footer {
  margin-top: auto;
  padding: 0px;
}

.App {
  touch-action: pan-y;
}

.drawer {
  transition: left 0.3s ease-out;
}

.language-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin-left: -14px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 0.8em;
  background-color: #fff;
  /* background-color: var(--secondary-color); */
  color: var(--main-text-color);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.language-toggle svg {
  margin-right: 8px;
}

.corporate-link {
  display: block;
  text-align: center;
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  margin-top: 10px;
  transition: color 0.3s ease;
}


/* Loading Styles */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  color: var(--disabled-text-color);
}

/* Owner Badge Styles */
.owner-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--main-text-color);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7em;
  font-weight: 100;
}

/* Loading Overlay Styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.loading-spinner {
  border: 4px solid var(--secondary-color);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-overlay p {
  color: var(--main-bg-color);
  margin-top: 10px;
  font-size: 1.2em;
}

/* Disabled Button Styles */
.add-to-board-button:disabled,
.remove-from-board-button:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
  opacity: 0.7;
}

/* Edit Icon Styles */
.edit-icon-container {
  position: absolute;
  top: 6px;
  right: 40px;
  cursor: pointer;
}

/* Edit Icon Styles */
.invite-icon-container{
  position: absolute;
  top: 5px;
  right: 70px;
  cursor: pointer;
}

.leave-icon-container {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  display: flex; /* Flexboxを有効にします */
  justify-content: center; /* 水平方向の中央揃え */
  align-items: center; /* 垂直方向の中央揃え */
  text-align: center;
  top: 6px;
  height: 20px;
  font-size: 0.6em;
  border-radius: 5px;
  border: var(--main-text-color) dotted 1px;
  color: var(--main-text-color);
  right: 70px;
  cursor: pointer;
}

/* Follow Button Styles */
.follow-button-container {
  position: absolute;
  top: 6px;
  right: 40px;
  z-index: 100;
}

.follow-button,
.unfollow-button {
  cursor: pointer;
}

.follow-loader {
  animation: spin 1s linear infinite;
}

.follow-count {
  font-size: 0.6em;
  padding-left: 4px;
  font-weight: 200;
}

.no-follow-list {
  font-size: 0.8em;
  color:var(--disabled-text-color)
}

.follow-info {
  position: absolute;
  /* right: 20px;
  top: 40px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.member-count{
  text-align: right;
  /* padding: 0; */
  /* margin: 0; */
  margin-bottom: 0px;
  padding-right: 5px;
  font-size: 0.8em;
}

/* Delete Board Button Styles */
.modal-content .delete-board-button {
  margin-top: 20px;
  width:100%;
  padding: 10px;
  background-color: var(--main-bg-color);
  color: var(--error-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.modal-content .delete-board-button:disabled {
  background-color: var(--main-bg-color);
  color: var(--disabled-text-color);
  cursor: not-allowed;
}

.modal-content .delete-board-button svg {
  margin-right: 8px;
}


/* BoardEditModal Styles */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 0;
  margin: 0;
  /* margin-bottom: 20px; */
}

.modal-header .background-button,
.modal-header .back-button,
.modal-header .avatar-button
{
  background: none;
  border: none;
  color: var(--main-text-color);  /* または適切な色 */
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 5px 0px;
  margin: 0;
  text-align: center;
  text-decoration: none;
}

/* BackgroundSelector Styles */
.background-selector {
  display: flex;
  flex-direction: column;
  height: 100%;
}


.background-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.background-option {
  aspect-ratio: 1;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  transition: border-color 0.3s ease;
}

.background-option.selected {
  border-color: var(--primary-color);
}

.background-option img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-color);
  color: var(--main-text-color);
  font-size: 0.8em;
}

.update-button {
  margin-top: auto;
  padding: 10px;
  background-color: var(--primary-color);
  color: var(--main-bg-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.update-button:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
}


/* Share Button Styles */
.share-button {
  position: absolute;
  top: 6px;
  right: 10px;
  z-index: 100;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: opacity 0.3s ease;
}

.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.share-modal-content {
  background-color: var(--main-bg-color);
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px var(--shadow-color);
}

.share-modal-content .share-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: 800;
  text-align: center;
}

.share-modal-content .share-sub-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.6em;
  text-align: center;
  font-weight: normal;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* 既存のスタイルは省略 */

/* ShareModal 用の新しいスタイル */
.share-image{
  box-shadow: 0 0 10px var(--shadow-color);
}


.share-options {
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
  margin-top: 20px;
}

.share-options button {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.8em;
  text-decoration: none;
}

.share-options svg{
  margin-right: 5px;
  /* padding-top: 5px; */
}

.share-buttons {
  display: flex;
  justify-content: center;
}

.share-button-general {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--main-bg-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.share-button-general:hover {
  background-color: var(--primary-hover-color);
}

.share-url {
  text-align: center;
  margin-bottom: 20px;
  word-break: break-all;
  font-size: 0.6em;
}

.share-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.share-buttons button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.comfirmation-modal p{
  font-size: 0.8em;
}

.how-to-links {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.how-to-link {
  background-color: #ffffffaa;
  color: var(--main-text-color);
  padding: 10px 15px;
  margin: 0 2%;
  /* border-radius: 20px; */
  border-left: 1.0px solid var(--border-color);
  border-right: 1.0px solid var(--border-color);
  text-decoration: none;
  transition: background-color 0.3s ease;
  display: inline-flex;
  flex-direction: column; /* 変更: 縦方向のflexに */
  align-items: center;
  justify-content: center;
  min-width: 150px;
  width: 30%;
  height: 70px; /* 追加: ボタンの高さを固定 */
  text-align: center;
}

.how-to-link-main {
  font-size: 1em;
  font-weight: 200;
  margin-bottom: 4px;
}

.how-to-link-sub {
  font-size: 0.6em;
  font-weight: 100;
}

.how-to-link.let-follow {
  /* margin-top: 100px;
  margin-bottom: 100px; */
  min-width: 300px;
  width: 30%;
}

.is-hidden-term {
  font-size: 0.6em;
  color: var(--disabled-text-color);
}


/* InviteModal Styles */
.modal-header {
  position: relative;
  margin-bottom: 0px;
}

.modal-actions {
  position: absolute;
  top: 3px;
  right: 0;
}

.modal-actions svg {
  cursor: pointer;
}

.member-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.member-list li {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

.member-list li:last-child {
  border-bottom: none;
}

.member-list li.selected {
  background-color: #e6f2ff;
  font-weight: bold;
}

.remove-member-button {
  width: 100%;
  padding: 12px 20px;
  background-color: var(--error-color);
  color: var(--main-bg-color);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.2s ease, opacity 0.2s ease;
}

.remove-member-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.no-members-message {
  color: var(--disabled-text-color);
}



/* クリップアバター周り */

.clipped-tables {
  padding: 0px;
}

.clipped-tables h3 {
  font-size: 1em;
  margin-bottom: 12px;
}

.clipped-tables ul {
  list-style-type: none;
  padding: 0;
}

.clipped-tables li {
  margin-bottom: 12px;
}

.cliper-info {
  display: flex;
  align-items: center;
}

.cliper-info .cliper-avatar {
  width: 30px;
  height: 30px;
  border-radius: 10%;
  margin-right: 10px;
  object-fit: cover;
}

.noclip-data{
  font-size: 0.6em;
  color: var(--disabled-text-color);
}

.clipped-table-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clipped-section{
  font-size: 0.8em;
  font-weight: 500;
}

.cliper-details {
  display: flex;
  flex-direction: column;
}

.clipped-tables .table-name {
  /* font-weight: bold; */
  font-size: 0.9em;
}

.clipped-tables .user-name {
  color: var(--disabled-text-color);
  font-size: 0.6em;
  margin-top: 2px;
}


.clip-avatars {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  margin-top: -12px;
}

.clip-avatar {
  width: 16px;
  height: 16px;
  border-radius: 10%;
  margin-right: 5px;
  object-fit: cover;
}

.remaining-count {
  /* background-color: #f0f0f0; */
  color: #333;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar Selector Styles */
.avatar-preview {
  cursor: pointer;
  width: 150px;
  height: 150px;
  border-radius: 5%;
  overflow: hidden;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
}

.avatar-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150%;
  width: 45%;
  background-color: #d7d7d7;
  border-radius: 50%;
}

.no-avatar svg {
  color: #ffffff;
}




.recommended-tags {
  margin-top: 60px;
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.recommended-tags h3 {
  font-size: 1em;
  margin-bottom: 20px;
}

.recommended-tags-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.recommended-tag-button {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.8em;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.recommended-tag-button.normal-tag,
.recommended-tag-button.content-tag,
.recommended-tag-button.sub_category-tag,
.recommended-tag-button.ip-tag {
  background-color: #ffffffdd;
  color: #949494;
  border: 1.0px solid #c0c0c0;
}

.recommended-tag-button.location-tag {
  background-color: #ffffffdd;
  color: #53b1ad;
  border: 1.0px solid #97d6d3;
}

.recommended-tag-button.area-tag {
  background-color: #ffffffdd;
  color: #ff7043;
  border: 1.0px solid #ffab91;
}

.recommended-tag-button.style-tag {
  background-color: #ffffffdd;
  color: #7e57c2;
  border: 1.0px solid #b39ddb;
}



/* tag rank display */

@keyframes followAddPulse {
  0% {
    transform: scale(1);
    color: inherit;
  }
  50% {
    transform: scale(1.2);
    color: #31b6f3; /* Twitter青 */
  }
  100% {
    transform: scale(1);
    color: inherit;
  }
}

@keyframes followRemovePulse {
  0% {
    transform: scale(1);
    color: inherit;
  }
  50% {
    transform: scale(0.8);
    color: #ff4500; /* 赤色 */
  }
  100% {
    transform: scale(1);
    color: inherit;
  }
}

.tag-rank-container.follow-add-pulse {
  animation: followAddPulse 0.5s ease-in-out;
}

.tag-rank-container.follow-remove-pulse {
  animation: followRemovePulse 0.5s ease-in-out;
}



.tag-rank-container {
  position: absolute;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 6px;
  height: 20px;
  font-size: 0.6em;
  border-radius: 5px;
  /* border: var(--main-text-color) dotted 1px; */
  color: var(--main-text-color);
  right: 70px;
}

/* item_fetch_error retry-button */

.fetch-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px 0;
  /* background-color: #fff1f0; */
  /* border: 1px solid #ffa39e; */ 
  border-radius: 4px;
}

.fetch-error-message {
  color: var(--disabled-text-color);
  font-size: 0.9em;
  margin-bottom: 15px;
  text-align: center;
}

.fetch-retry-button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.fetch-retry-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.fetch-retry-button:disabled {
  background-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
  .fetch-error-container {
    padding: 15px;
    margin: 15px 0;
  }

  .fetch-error-message {
    font-size: 0.8em;
  }

  .fetch-retry-button {
    font-size: 0.8em;
    padding: 6px 12px;
  }
}


/* tips */

.tips-container {
  position: fixed;
  z-index: 99999;
  max-width: 200px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 12px;
  animation: fadeIn 0.3s ease-out;
  margin: 10px;
}

.tips-container::before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 8px;
}

.tips-container.top::before {
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  border-color: #fff transparent transparent transparent;
}

.tips-container.bottom::before {
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  border-color: transparent transparent #fff transparent;
}

.tips-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tips-content p {
  margin: 0;
  font-size: 0.6em;
  text-align: center;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* タップ可能なスタイルを追加 */
.tag-rank-container {
  cursor: pointer;
}



/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .loading-content, .loading-more, .no-cards-message {
    font-size: 0.8em;
  }

  .board-description {
    max-width: 90%;
  } 

  .past-events-link {
    font-size: 0.4em;
    bottom: -5px; 
  }

  .tab-content {
    padding: 0px;
  }

  .term-info {
    margin-bottom: 8px;
    padding: 8px;
  }

  .tabs button {
    padding: 5px 5px;
  }

  .comment-item {
    margin-bottom: 8px;
  }

  .comments-container {
    padding: 0px;
  }
  
  .comment-avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    margin-right: 6px;
  }
  
  .comment-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }  

  .comment-user {
    font-size: 0.8em;
  }
  
  .comment-time {
    font-size: 0.6em;
  }
  
  .comment-text {
    font-size: 0.8em;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  .comment-actions button {
    font-size: 0.6em;
  }

  .comment-form.active {
    padding: 8px;
    display: flex;
    align-items: center;
  }

  .comment-form input {
    font-size: 0.8em;
    padding: 6px;
    padding-left: 10px;
    flex-grow: 1;
    min-width: 0;
  }

  .comment-form button {
    font-size: 0.8em;
    padding: 6px 12px;
    white-space: nowrap;
    flex-shrink: 0;
  }

  .add-to-board-container {
    padding: 8px;
  }
  
  .add-to-board-button, .remove-from-board-button {
    padding: 6px;
    border-radius: 4px;
    font-size: 0.8em;
  }

  .no-content {
    font-size: 0.8em;
  }
  
  .overview-text {
    font-size: 0.8em;
  }


  .drawer-toggle {
    left: 0px;
  }

  .drawer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -80%;
    width: 80%;
    max-width: 300px;
  }

  .drawer.open {
    left: 0;
  }

  .language-toggle:active {
    color: var(--primary-hover-color);
    /* background-color: var(--secondary-hover-color); */
  }

  .owner-badge {
    top: 5px;
    right: 5px;
    font-size: 0.6em;
    padding: 1px 4px;
  }

  .floating-add-button {
    width: 50px;
    height: 50px;
    font-size: 24px;
    bottom: 90px;
    right: 15px;
  }

  .floating-search-button {
    width: 50px;
    height: 50px;
    bottom: 30px;
    right: 15px;
  }

  .tag-modal-content {
    width: 95%;
    padding: 15px;
  }

  .tag-modal-content h2 {
    font-size: 1.1em;
  }

  .tag-item {
    font-size: 0.8em;
  }

  .loading-spinner {
    width: 30px;
    height: 30px;
  }

  .loading-overlay p {
    font-size: 1em;
  }

  .add-to-board-button,
  .remove-from-board-button {
    font-size: 0.7em;
    padding: 8px;
  }

  .term-options {
    padding: 0px;
  }

  .modal-content .delete-board-button {
    font-size: 0.8em;
    padding: 8px;
  }

  .modal-content button .remain-time {
    font-size: 0.7em;
  }

  .modal-form-input {
    font-size: 0.9em;
    padding: 6px;
  }

  .char-count {
    font-size: 0.7em;
  }

  .error-message {
    font-size: 0.8em;
  }

  .share-modal-content {
    width: 95%;
    padding: 15px;
  }

  .share-modal-content h2 {
    font-size: 1.1em;
  }

  .share-modal-content h3 {
    font-size: 0.9em;
  }

  .app-discription {
    font-size: 0.6em;
    margin-bottom: 15px;
  }

  .background-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .toast {
    top: 10px;
    padding: 10px 20px;
    font-size: 12px;
  }

  .board-edit-modal .modal-header .background-button:active,.board-edit-modal .modal-header .back-button:active {
    color: var(--primary-hover-color);
  }

  .background-option:active {
    color: var(--primary-hover-color);
  }

  .board-edit-modal .update-button {
    font-size: 0.9em;
  }

  .past-events-link:active {
    color: var(--primary-hover-color);
  }

  .card:active {
    transform: scale(1.03);
  }

  .card img:active {
    transform: scale(1.05);
  }

  .placeholder:active {
    transform: scale(1.05);
  }

  .tag-link:active {
    background-color: var(--secondary-hover-color);
  }

  .modal-content li:active {
    background-color: var(--light-bg-color);
  }

  .modal-content button:active {
    background-color: var(--primary-hover-color);
  }

  .nav-item:active, .board-item:active, .tag-item:active {
    background-color: var(--light-bg-color);
  }

  .logout-button:active, .login-button:active {
    background-color: var(--primary-hover-color);
  }

  .edit-icon-container:active {
    color: var(--primary-hover-color);
  }

  .follow-button:active,
  .unfollow-button:active {
    color: var(--primary-hover-color);
  }

  .modal-content .delete-board-button:active {
    background-color: var(--secondary-color);
  }

  .share-button:active {
    color: var(--primary-hover-color);
  }

  .share-options button:active {
    color: var(--primary-hover-color);
  }  

  .share-button-general:active {
    background-color: var(--primary-hover-color);
  }

  .floating-add-button:active {
    background-color: var(--primary-hover-color);
    transform: scale(1.1);
  }

  .floating-search-button:active {
    background-color: var(--primary-hover-color);
    transform: scale(1.1);
  }

  .tag-item:active {
    background-color: var(--secondary-hover-color);
  }

  .remove-from-board-button:active {
    color: var(--primary-hover-color);
  }

  .add-to-board-button:active {
    color: var(--primary-hover-color);
  }

  .calendar-icon:active img {
    opacity: 0.7;
  }

  .change-button button:active {
    background-color: var(--main-bg-color);
  }

  .how-to-link:active {
    background-color: var(--light-bg-color);
  }

  .tag-modal-content .tag-item:active {
    transform: scale(1.01);
    /* box-shadow: 0 2px 4px var(--shadow-color); */
    /* background-color:var(--secondary-hover-color); */
  }

  .clear-all-button:active {
    background-color: var(--secondary-hover-color);
  }
  
  .search-button:active {
    background-color: var(--primary-hover-color);
  }

  .tag-modal-content .toggle-selection-mode:active {
    background: var(--main-bg-color);
    color: var(--primary-hover-color);
  }

  .clipped-table-item:active {
    background-color: var(--light-bg-color);
  }

  .search-input-container .reset-search-button:active {
    background: var(--main-bg-color);
    color: var(--primary-hover-color);
  }

  .recommended-tag-button:active {
    background-color: var(--secondary-hover-color);
  }

  .fetch-retry-button:active {
    background-color: #40a9ff;
  }

}

/* PC-specific hover effects */
@media (min-width: 769px) {
  
  .board-edit-modal .modal-header .background-button:hover,.board-edit-modal .modal-header .back-button:hover {
    color: var(--primary-hover-color);
  }
/* 
  .background-option:hover {
    border-color: var(--secondary-color);
  } */

  .board-edit-modal .update-button:hover:not(:disabled) {
    background-color: var(--primary-hover-color);
  }
  
  .past-events-link:hover {
    color: var(--primary-hover-color);
  }

  .card:hover {
    transform: scale(1.03);
  }

  .card img:hover {
    transform: scale(1.05);
  }

  .placeholder:hover {
    transform: scale(1.05);
  }

  .tag-link:hover {
    background-color: var(--secondary-hover-color);
  }

  .modal-content li:hover {
    background-color: var(--light-bg-color);
  }

  .modal-content button:hover {
    background-color: var(--primary-hover-color);
  }

  .nav-item:hover, .board-item:hover {
    background-color: var(--light-bg-color);
  }

  .logout-button:hover, .login-button:hover {
    background-color: var(--primary-hover-color);
  }

  .language-toggle:hover {
    color: var(--primary-hover-color);
    /* background-color: var(--secondary-hover-color); */
  }

  .edit-icon-container:hover {
    color: var(--primary-hover-color);
  }

  .follow-button:hover,
  .unfollow-button:hover {
    color: var(--primary-hover-color);
  }

  .modal-content .delete-board-button:hover {
    background-color: var(--secondary-color);
  }

  .share-button:hover {
    color: var(--primary-hover-color);
  }

  .share-options button:hover {
    color: var(--primary-hover-color);
  }
  

  .share-button-general:hover {
    background-color: var(--primary-hover-color);
  }

  .floating-add-button:hover {
    background-color: var(--primary-hover-color);
    transform: scale(1.1);
  }

  .floating-search-button:hover {
    background-color: var(--primary-hover-color);
    transform: scale(1.1);
  }

  .remove-from-board-button:hover {
    color: var(--primary-hover-color);
  }

  .add-to-board-button:hover {
    color: var(--primary-hover-color);
  }

  .calendar-icon:hover img {
    opacity: 0.7;
  }

  .change-button button:hover {
    background-color: var(--main-bg-color);
  }

  .comment-actions button:hover {
    text-decoration: underline;
  }

  .fullscreen-add-form .add-url-form button:hover {
    background: var(--primary-hover-color);
  }

  .add-form-content button:hover {
    background: var(--primary-hover-color);
  }

  .how-to-link:hover {
    background-color: var(--light-bg-color);
  }

  .tag-list-container .tag-item:hover {
    transform: scale(1.01);
    box-shadow: 0 2px 4px var(--shadow-color);
    color: inherit;
    background-color: inherit;
  }

  .tag-item.selected:hover {
    background-color: var(--primary-color);
    color: var(--main-bg-color);
    border-color: var(--primary-color);
  }
  
  .tag-item.excluded:hover {
    background-color: var(--error-color);
    color: var(--main-bg-color);
    border-color: var(--error-color);
    text-decoration: line-through;
  }
  
  .search-actions .clear-all-button:hover {
    background-color: var(--secondary-hover-color);
  }
  
  .search-actions .search-button:hover {
    background-color: var(--primary-hover-color);
  }

  .tag-modal-content .toggle-selection-mode:hover {
    background: var(--main-bg-color);
    color: var(--primary-hover-color);
  }

  .clipped-table-item:hover {
    background-color: var(--light-bg-color);
  } 

  .search-input-container .reset-search-button:hover {
    background: var(--main-bg-color);
    color: var(--primary-hover-color);
  }

  .recommended-tag-button:hover {
    background-color: var(--secondary-hover-color);
  }

  .fetch-retry-button:hover {
    background-color: #40a9ff;
  }

}